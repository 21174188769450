import { Button, Spacer, Paragraph, Heading, useBreakpointValue } from '@qasa/qds-ui'
import { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { getSearchQueryString } from '@qasa/app/src/utils/search'
import { HomeRentalTypeEnum } from '@qasa/graphql'

import bbqLandingPageBackground from '../../assets/images/blocket_landing_page_background.jpg'
import { isMobile } from '../../ui-styles/media-queries'
import { currentBackendPlatform, currentBrand } from '../../config'
import { Image } from '../../ui-shared/_core/image'
import { BRAND_CONFIG } from '../../config/brand-configurations'
import { InlineUsps } from '../../ui-shared/inline-usps'
import { AreaSearchAutocompleteLanding } from '../../legacy-pages/landing/components/hero/area-search-autocomplete'
import { availableRentalTypes } from '../../config/markets'
import { useRouter } from '../../vendor/next'
import { AreaSearchDialog } from '../find-home/area-search'
import { DEFAULT_FILTER_VALUES } from '../find-home/filters/default-filter-values'
import { SearchHistoryProvider } from '../find-home/search-history/search-history-context'

import { CITY_HOME_COUNT } from './place.gql'
import { NumberOfHomesCounter } from './number-of-homes-counter'
import { SearchBoxTabs, SearchTabEnum } from './search-box-tabs'

const TOP_SECTION_MARGIN = 200

const Wrapper = styled(Image)({
  minHeight: 650,
  height: `calc(100vh - ${TOP_SECTION_MARGIN}px)`,
  maxHeight: 1000,
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
const WhiteGradient = styled.div({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(to bottom, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 25%)',
})
const DisplayHeading = styled(Heading)(({ theme }) => ({
  ...theme.typography.display.sm,
  fontSize: theme.typography.title.md.fontSize,
  // NOTE: needed here since we override this for Blocket (where this heading is used)
  fontWeight: 700,
  [theme.mediaQueries.mdUp]: {
    fontSize: theme.typography.display.sm.fontSize,
  },
}))
const SearchSection = styled.div(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.core.white,
  borderRadius: theme.radii.xs,
  boxShadow: theme.shadows.md,
  padding: 24,
  margin: 16,
  maxWidth: 720,
  [isMobile]: {
    padding: 16,
    marginTop: 80,
    height: 'auto',
  },
}))
const SearchForm = styled.form(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacing['2x'],
  alignItems: 'center',
  [isMobile]: {
    flexWrap: 'wrap',
  },
}))
const FlexGrow = styled.div({
  flex: 1,
})

export function TopSection() {
  const { t } = useTranslation('landing_top_section')
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER_VALUES)
  const [searchTab, setSearchTab] = useState(SearchTabEnum.LONGTERM)
  const { push } = useRouter()
  const isDesktop = useBreakpointValue({ base: false, md: true }, { ssr: true })
  const wrapperSize = useBreakpointValue(
    {
      base: { width: 750, height: 1224 },
      md: { width: 3360, height: 2238 },
    },
    { ssr: true },
  )

  const { data: homeCountData } = useQuery(CITY_HOME_COUNT, {
    variables: {
      searchParams: {
        platform: currentBackendPlatform,
        rentalType:
          searchTab === SearchTabEnum.LONGTERM
            ? [HomeRentalTypeEnum.long_term]
            : [HomeRentalTypeEnum.vacation],
      },
    },
  })

  const hasMultipleRentalTypes = availableRentalTypes.length > 1

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    push(`/${searchTab}${getSearchQueryString({ filterValues })}`)
  }

  return (
    <Wrapper asDiv src={bbqLandingPageBackground} {...wrapperSize}>
      <WhiteGradient>
        <SearchSection>
          <NumberOfHomesCounter homeCount={homeCountData?.homeSearch.documents.totalCount} />
          <Spacer size="1x" />

          <DisplayHeading>
            {t([`${currentBrand}.title`, 'title'], { brandName: BRAND_CONFIG.name })}
          </DisplayHeading>
          <Spacer size="2x" />
          <Paragraph>{t([`${currentBrand}.subtitle`, 'subtitle'])}</Paragraph>
          <Spacer size="6x" />
          {hasMultipleRentalTypes && <SearchBoxTabs selectedTab={searchTab} setSelectedTab={setSearchTab} />}
          <Spacer size="6x" />
          <SearchForm onSubmit={handleSubmit}>
            <FlexGrow>
              <SearchHistoryProvider rentalType={HomeRentalTypeEnum.long_term}>
                {isDesktop ? (
                  <AreaSearchAutocompleteLanding
                    value={filterValues.searchAreas}
                    onChange={(newValue) => setFilterValues((old) => ({ ...old, searchAreas: newValue }))}
                  />
                ) : (
                  <AreaSearchDialog
                    value={filterValues.searchAreas}
                    onChange={(newValue) => setFilterValues((old) => ({ ...old, searchAreas: newValue }))}
                    shouldShowSearchHistory
                  />
                )}
              </SearchHistoryProvider>
            </FlexGrow>
            {!isDesktop && (
              <Button type="submit" variant="primary" size="sm" isFullWidth>
                {t('search')}
              </Button>
            )}
          </SearchForm>
          <Spacer size="4x" />
          <InlineUsps
            usps={t(
              searchTab === SearchTabEnum.LONGTERM
                ? [`${currentBrand}.search_box_usps`, 'search_box_usps']
                : 'search_box_usps_vacation',
              {
                returnObjects: true,
              },
            )}
            direction={isDesktop ? 'row' : 'column'}
          />
        </SearchSection>
      </WhiteGradient>
    </Wrapper>
  )
}
